import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FormInput, formInputProps } from '../FormInput';
import Eye from '../../../../images/svg/eye.svg';
import EyeSlash from '../../../../images/svg/eye-slash.svg';

export const FormPasswordInput = ({
  errors,
  name,
  touched,
  showPasswordTest,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const hasError = errors[name] && touched[name];
  const toggleShowPassword = () => setShowPassword(!showPassword);

  return (
    <div
      className={`flex bg-white border border-solid rounded-full ${
        hasError ? 'border-red' : 'border-grey-2'
      }`}
      data-testid="form-group-field-container"
    >
      <FormInput
        errors={errors}
        touched={touched}
        name={name}
        className="py-4 pl-8 rounded-l-full"
        type={showPassword ? 'text' : 'password'}
        unstyled
        {...rest}
      />
      <button
        className={`flex items-center pr-8 focus:outline-none`}
        onClick={toggleShowPassword}
        type="button"
        data-testid={showPasswordTest}
        tabIndex="-1"
      >
        {showPassword ? (
          <EyeSlash
            className={`w-4 ${hasError ? 'fill-red' : 'fill-grey-2'}`}
          />
        ) : (
          <Eye className={`w-4 ${hasError ? 'fill-red' : 'fill-grey-2'}`} />
        )}
      </button>
    </div>
  );
};

FormPasswordInput.propTypes = {
  ...formInputProps,
  showPasswordTest: PropTypes.string,
};
