export const validateAllErrors = async (schema, values) => {
  try {
    await schema.validate(values, { abortEarly: false });
    return {};
  } catch (errors) {
    const valueKeys = Object.keys(values);
    const getError = (item) => (acc, error) => {
      if (error.path === item) {
        acc.push(error.errors[0]);
      }
      return acc;
    };
    const createErrors = (acc, item) => {
      const { inner } = errors;
      const errorsArray = inner && inner.reduce(getError(item), []);
      acc[item] = errorsArray.length ? errorsArray : null;
      return acc;
    };

    return valueKeys.reduce(createErrors, {});
  }
};
