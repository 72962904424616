import React, { Fragment } from 'react';
import { ErrorMessage } from '../ErrorMessage';

const PasswordHints = ({ errors }) => {
  const hint = 'rounded-full px-2 mr-1 text-sm py-1';
  const valid = 'text-black bg-green';
  const notValid = 'text-white bg-grey-2';

  const lengthClass = `${hint} ${errors.includes('LENGTH') ? notValid : valid}`;
  const capitalClass = `${hint} ${
    errors.includes('CAPITAL') ? notValid : valid
  }`;
  const numberClass = `${hint} ${errors.includes('NUMBER') ? notValid : valid}`;

  return (
    <div className="pl-8">
      <div className="text-grey-2 text-sm">Vaše nové heslo musí obsahovat:</div>
      <div>
        <span className={lengthClass}>8 znaků</span>
        <span className={capitalClass}>1 velké písmeno</span>
        <span className={numberClass}>1 číslo</span>
      </div>
    </div>
  );
};

export const PasswordStrengthAnalyzer = ({ errors, dirty }) => {
  const errorsArray = errors || [];
  const showRequired = errorsArray.includes('REQUIRED');

  return (
    <Fragment>
      {showRequired && <ErrorMessage>Toto pole je povinné.</ErrorMessage>}
      {!showRequired && dirty && <PasswordHints errors={errorsArray} />}
    </Fragment>
  );
};
